import { from, of } from 'rxjs';
import { combineEpics, ofType } from 'redux-observable';
import { map, switchMap, catchError, mergeMap } from 'rxjs/operators';
import { push } from 'react-router-redux';

import { handleError, handleErrorDetailed, trimAllFields } from '../../api_helper';
import { api } from '../../api';
import { addNotification } from '../NotificationGenerator/slice';
import i18n from '../../i18n';
import {
  signUp2Send,
  signUpVerify,
  signUpVerifySucc,
  signUpVerifyError
} from './slice';
import { SIGNUP_COMPLETE_URL, SIGNUP_VERIFY_URL } from '../../api/apiUrls';

function signUp2VerifyEpic(action$) {
  return action$.pipe(
    ofType(signUpVerify),
    map((action) => action.payload),
    switchMap(({ verifyCode, language }) => (
      from(api.post(SIGNUP_VERIFY_URL, { code: verifyCode, language }, { language })).pipe(
        catchError(handleError),
        mergeMap((result) => {
          if (result?.email) {
            return of(
              signUpVerifySucc({ fields: result, verifyCode })
            );
          }

          return of(
            signUpVerifyError({ message: result }),
            addNotification({ type: 'error', text: result })
          );
        })
      )
    ))
  );
}

function signUp2SendEpic(action$) {
  return action$.pipe(
    ofType(signUp2Send),
    map((action) => action.payload.fields),
    switchMap((fields) => (
      from(api.post(SIGNUP_COMPLETE_URL,
        {
          ...trimAllFields(fields),
          email: undefined,
          pv_installer: undefined,
          'rep-password': undefined
        },
        { language: fields.language }
      )).pipe(
        catchError(handleErrorDetailed),
        mergeMap((result) => {
          if (result && !result.error) {
            return of(
              push('/login'),
              addNotification({ type: 'success', text: i18n.t('success') })
            );
          }

          return of(
            addNotification({ type: 'error', text: result?.message || result?.error || result })
          );
        }
        )
      )
    ))
  );
}

export default combineEpics(
  signUp2VerifyEpic, signUp2SendEpic
);
