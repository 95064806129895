export const multiselectConfig = {
  multiselectContainer: {
    display: 'flex',
    alignItems: 'end',
    width: 'min-content',
    minWidth: '200px',
    maxWidth: '250px',
    minHeight: '38px'
  },
  searchBox: {
    width: '100%',
    border: '1px solid rgba(255, 255, 255, 0.1)',
    color: 'rgba(255, 255, 255, 0.1) !important',
    backgroundColor: 'transparent'
  },
  chips: {
    backgroundColor: '#1d8bd2',
    whiteSpace: 'normal'
  },
  optionContainer: {
    position: 'absolute',
    width: '100%',
    border: 'none',
    backgroundColor: '#033050',
    top: '4px'
  },
  inputField: {
    maxWidth: '240px'
  }
};

export const READ_ONLY_ROLES = ['pv_installer_employee_read_only', 'oem_employee_read_only'];
