import React from 'react';
import {
  RenderUserNameMonitoring,
  RenderDate,
  RenderWorkStatus,
  RenderPriorityStatus,
  RenderSignalIcon,
  RenderAssignedStatus,
  RenderPlant,
  RenderLastLogin,
  RenderMonitoringTableButtons,
  RenderGateway
} from '../../components/ReactTable/renderFunctions';
import { EditColumns } from '../../components';
import i18n from '../../i18n';

import './index.scss';

const columns = (myRoleType, { columns: additionalColumns, width, queries }) => ([
  {
    Header: i18n.t('signal'),
    id: 'signal',
    minWidth: width.signal,
    className: 'monitoringSignalColumn',
    Cell: RenderSignalIcon
  },
  {
    Header: i18n.t('SMID'),
    id: 'sm_id',
    accessor: (d) => (d.gateway?.sm_id || undefined),
    Cell: (row) => RenderGateway(row),
    minWidth: width.sm_id,
    className: 'sm_id',
    show: additionalColumns.sm_id
  },
  {
    Header: i18n.t('plantLabel'),
    id: 'plant',
    minWidth: width.plant,
    className: '',
    Cell: RenderPlant,
    show: additionalColumns.plant
  },
  {
    Header: i18n.t('eUser'),
    id: 'name',
    minWidth: width.name,
    className: '',
    Cell: RenderUserNameMonitoring
  },
  {
    Header: i18n.t('userSettingNotesLabel'),
    id: 'note',
    accessor: 'user.note',
    minWidth: width.note,
    filterable: false,
    show: additionalColumns.note
  },
  {
    Header: i18n.t('city'),
    id: 'city',
    accessor: 'user.city',
    minWidth: width.city,
    show: additionalColumns.city
  },
  {
    Header: i18n.t('PV Installer'),
    id: 'company',
    accessor: (d) => (d.company?.name || i18n.t('noComp')),
    minWidth: width.company,
    className: 'pv_installer-td',
    show: (['root', 'solar_admin', 'property_management'].includes(myRoleType))
  },
  {
    Header: i18n.t('oem'),
    id: 'connectedOem',
    accessor: (d) => (d.connectedOem ? d.connectedOem.name : i18n.t('NoOEM')),
    minWidth: width.company,
    show: additionalColumns.oem
  },
  {
    Header: i18n.t('License'),
    id: 'license',
    accessor: 'license.name',
    minWidth: width.license,
    className: 'pv_installer-td',
    show: additionalColumns.license
  },
  {
    Header: i18n.t('Creation Date'),
    id: 'createdAt',
    accessor: (d) => (d.createdAt || i18n.t('dd.mm.yyyy')),
    minWidth: width.createdAt,
    className: 'creation-date-td',
    Cell: RenderDate
  },
  {
    Header: i18n.t('lastErrorDate'),
    accessor: 'lastErrorDate',
    minWidth: width.lastErrorDate,
    className: 'last_error_date-td',
    Cell: RenderDate
  },
  {
    Header: <div className="rt-resizable-header-content">{i18n.t('assigned')}</div>,
    id: 'assigned',
    accessor: 'assigned',
    Cell: (row) => RenderAssignedStatus(row, ['property_management'].includes(myRoleType)),
    minWidth: width.assigned,
    className: 'assigned-td',
    resizable: false
  },
  {
    Header: () => <div className="rt-resizable-header-content">{i18n.t('priority')}</div>,
    accessor: 'priority',
    minWidth: width.priority,
    Cell: RenderPriorityStatus,
    className: 'priority-td',
    resizable: false
  },
  {
    Header: () => <div className="rt-resizable-header-content">{i18n.t('workStatus')}</div>,
    accessor: 'status',
    minWidth: width.status,
    resizable: false,
    Cell: RenderWorkStatus,
    className: 'work_status-td rt-resizable-header-content'
  },
  {
    Header: i18n.t('lastLogin'),
    accessor: 'last_login',
    minWidth: width.last_login,
    className: 'last_login-td',
    Cell: (row) => RenderLastLogin(row),
    show: additionalColumns.last_login
  },
  {
    Header: i18n.t('installationDate'),
    accessor: 'installationDate',
    minWidth: width.installationDate,
    className: 'installation_date-td',
    Cell: (row) => RenderDate({ value: row.original?.user?.createdAt }),
    show: additionalColumns.installationDate
  },
  {
    Header: i18n.t('installationFinishedDate'),
    accessor: 'installationFinishedDate',
    minWidth: width.installationFinishedDate,
    className: 'installation_date-td',
    Cell: (row) => RenderDate({ value: row.original?.gateway?.installationFinishedDate }),
    show: additionalColumns.installationFinishedDate && queries.isInstallationCompleted !== false
  },
  {
    Header: i18n.t('lastConnection'),
    accessor: 'lastStreamUpdate',
    minWidth: width.lastStreamUpdate,
    className: 'last-update-td',
    Cell: (row) => RenderDate({ value: row.original?.gateway?.lastStreamUpdate }),
    show: additionalColumns.lastStreamUpdate
  },
  {
    Header: i18n.t('kWPeakOutputLabel'),
    accessor: 'settings.kWp',
    minWidth: width.kWp,
    id: 'kWp',
    className: 'last-update-td',
    show: additionalColumns.kWp,
    Cell: ({ value }) => Number.isFinite(value) && `${Math.round(value * 100) / 100} ${i18n.t('kWp')}`

  },
  {
    Header: EditColumns,
    accessor: 'buttons',
    width: width.buttons,
    className: 'action-buttons',
    Cell: (row) => RenderMonitoringTableButtons(
      row.original,
      {
        sendEmail: (['root', 'solar_admin', 'property_management', 'pv_installer', 'pv_installer_employee', 'pv_installer_employee_read_only', 'pv_installer_employee_read_install', 'oem', 'oem_employee', 'oem_employee_read_only'].includes(myRoleType)),
        editNote: (['root', 'solar_admin', 'property_management', 'pv_installer', 'pv_installer_employee', 'pv_installer_employee_read_only', 'pv_installer_employee_read_install', 'oem', 'oem_employee', 'oem_employee_read_only'].includes(myRoleType)),
        devices: { preLink: '/users/' }
      }
    ),
    filterable: false,
    sortable: false
  }
]);

export default columns;
