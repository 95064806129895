import { createSelector } from '@reduxjs/toolkit';

import i18n from '../../i18n';
// import { dataListID } from '../SubUsers/constants';

import { getModalsEditRowDataSelector } from '../../redux-store/selectors/modals';
import { getUserDevicesLoad, getUserDevicesUser, getUserDevicesUser_IdSelector } from '../../redux-store/selectors/userDevices';

import { getSignInUser, getSignInUserRoleType, getSignInUser_Id } from '../../redux-store/selectors/signIn';
import { getDataListSelector } from '../../redux-store/selectors/dataList';

export const editModalDataSelector = createSelector([getModalsEditRowDataSelector], (editModalData) => (editModalData));

export const titleForIpModalWindowSelector = createSelector(
  [getUserDevicesLoad],
  (load) => (load ? i18n.t('Scanning IP') : i18n.t('Choose IP'))
);

export const checkMyRoleTypeSelector = createSelector(
  [getSignInUserRoleType],
  (roleType) => (roleType === 'end_user' && 'my')
);

export const getSmSubUsersSelectorId = createSelector(
  [getUserDevicesUser_IdSelector, getSignInUser_Id],
  (userDevice_id, user_id) => userDevice_id || user_id
);

const getDataListSensorsSub = createSelector(
  [getDataListSelector],
  (dataList) => dataList?.['sub-meter']
);

export const getSubSensorsFromSensors = createSelector(
  [getDataListSensorsSub],
  (sensors) => sensors?.data
);

export const getUserLicenseSelector = createSelector(
  [getSignInUser, getUserDevicesUser],
  (user, userDevices) => (user?.role?.type === 'end_user' ? user?.license : userDevices?.license)
);

export const getUserHardwareSelector = createSelector(
  [getSignInUser, getUserDevicesUser],
  (user, userDevices) => (user?.role?.type === 'end_user' ? user?.gateway?.hardware : userDevices?.gateway?.hardware)
);
