import React, { useMemo, useState } from 'react';
import ReactTable from 'react-table';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { DataListPagination, DataListFilterV2, DataListV2 } from '../DataList';
import { CompanyInfoSub } from '../../components/ReactTable/renderFunctions';
import { PrimaryButton } from '../../components/UIKit';
import { AddOEMWindow, DeleteOEMWindow } from './modals';
import { Footer, SearchField, TopBar, Pagination, Breadcrumbs } from '../../components';
import { sendFileRequest as oemRequest } from '../PvInstallers/actions';
import {
  openModalWindow,
  modalWindowSend,
  ADD_DATA_MODAL_ID,
  REMOVE_DATA_MODAL_ID
} from '../ModalWindow/slice';
import { dataListReloadData } from '../DataList/slice';

import { getPagesSettingsSelector } from '../../redux-store/selectors/pagesSettings';

import columns from './columns';
import { DATA_LIST_ID } from './constants';
import { OEM_URL, USERS_EXPORTS_OEMS, NEW_OEM_URL } from '../../api/apiUrls';

import i18n from '../../i18n';

import '../Users/index.scss';
import { ConfirmDeleteUserWindow } from '../../modals';
import { transformResponse } from '../../utils/transformResponse';

/**
 * Renders PV installers page
 * @memberof module:OEM
 */
const OEM = (props) => {
  const { myRoleType } = props;
  const dispatch = useDispatch();

  const [expanded, setExpanded] = useState({});

  const { [DATA_LIST_ID]: pageSettings } = useSelector(getPagesSettingsSelector);
  const isAdmin = ['root', 'solar_admin'].includes(myRoleType);
  const columnsOEMs = useMemo(() => columns(myRoleType, pageSettings), [myRoleType, pageSettings]);
  const defaultParams = pageSettings.queries;

  const sendAddUser = (fields) => dispatch(modalWindowSend({
    modalID: ADD_DATA_MODAL_ID,
    requestConfig: {
      method: 'post',
      url: OEM_URL,
      data: fields
    },
    cb: () => dataListReloadData({ listID: DATA_LIST_ID })
  }));

  const sendRemoveUser = ({ id }) => dispatch(modalWindowSend({
    modalID: REMOVE_DATA_MODAL_ID,
    requestConfig: {
      method: 'delete',
      url: `/../user/${id}`
    },
    cb: () => dataListReloadData({ listID: DATA_LIST_ID })
  }));

  const exportCSV = () => {
    dispatch(oemRequest({
      method: 'get',
      url: USERS_EXPORTS_OEMS,
      config: { responseType: 'blob' },
      onResult: { errorMessage: 'exportRequestError', downloadFile: true }
    }));
  };

  const onClickHandler = () => dispatch(openModalWindow({ modalID: ADD_DATA_MODAL_ID, data: null }));

  return (
    <div className="main-table-container">
      <AddOEMWindow onSubmit={sendAddUser} />
      <DeleteOEMWindow onSubmit={sendRemoveUser} />
      <ConfirmDeleteUserWindow userRole={i18n.t('oem').toLowerCase()} />

      <TopBar updateButton showNavigation />
      <div className="container-fluid">
        <Breadcrumbs crumbs={[{ name: i18n.t('OEM') }]} />

        <div className="m-form m-form--label-align-right m--margin-top-20 m--margin-bottom-30">
          <div className="col-xl-12">
            <div className="row align-items-center">
              <div
                className={`col-xl-4 col-sm-7 col-lg-4 col-md-5 col-8 order-1 ${isAdmin ? '' : 'mr-auto'}`}
              >
                <DataListFilterV2
                  Component={SearchField}
                  componentProps={{
                    defaultValue: pageSettings.queries.search,
                    searchByFields: 'email,first_name,last_name,my_company,street,city,zip,phone',
                    placeholder: i18n.t('searchAllTablesElements')
                  }}
                  listID={DATA_LIST_ID}
                  listURL={NEW_OEM_URL}
                  transformResponse={transformResponse}
                />
              </div>
              {isAdmin && (
                <div className={`col-4 col-lg-2 order-2 ${isAdmin ? 'mr-auto' : ''}`}>
                  <button
                    type="button"
                    onClick={exportCSV}
                    className="btn m-btn--pill m-btn--air btn-secondary btn-table-inst btn-comp exportBtn"
                  >
                    {i18n.t('exportOems')}
                  </button>
                </div>
              )}
              {myRoleType !== 'viewer' && (
                <div className="col-auto order-3 btn-sm-us">
                  <PrimaryButton onClickHandler={onClickHandler} i18nLabel="addOEMbtn" isLargeButton />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="table-container m_datatable m-datatable m-datatable--default m-datatable--brand m-datatable--loaded">
          <DataListV2
            listID={DATA_LIST_ID}
            listURL={NEW_OEM_URL}
            params={defaultParams}
            transformResponse={transformResponse}
            Component={ReactTable}
            componentProps={{
              defaultPageSize: 0,
              className: '-highlight m-datatable__table',
              columns: columnsOEMs,
              SubComponent: CompanyInfoSub,
              manual: true,
              onExpandedChange: (all) => setExpanded(all),
              expanded
            }}
          />
          <DataListPagination
            Component={Pagination}
            listID={DATA_LIST_ID}
            defaultPageSize={10}
          />
        </div>
      </div>
      <Footer />
    </div>
  );
};

OEM.propTypes = {
  myRoleType: PropTypes.string.isRequired
};

export default OEM;
