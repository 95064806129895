import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { email, required, minLength, maxLength } from '../../validation';
import DataList from '../../DataList';
import ModalWindow from '../../ModalWindow';
import { Preloader, Tooltip } from '../../../components';
import { getOEMs, getOEMsByID, clearOEMs } from '../slice';
import { RadioButtons, CustomTextInput, CustomSelect, Checkbox } from '../../../components/ReduxFormFields';
import { addUserAdmFormID, addUserForm, dataListPviId, getPvInstallersConfig } from '../constants';
import { EMPLOYEE_ROLE_TYPES } from '../../UserDetails/constants';
import { PrimaryButton, CancelButton } from '../../../components/UIKit';
import { getUsersOemsListSelector } from '../../../redux-store/selectors/users';
import { getDataListCompaniesDataSelector } from '../../../redux-store/selectors/dataList';
import { OEM_CONNECTED_OEMS_URL } from '../../../api/apiUrls';
import { ADD_DATA_MODAL_ID, closeModalWindow } from '../../ModalWindow/slice';
import { getSignInUserRoleTypeSelector } from '../../../redux-store/selectors/signIn';

import { APP_ID, HELION_APP_ID, SOLAR_MANAGER_APP_ID } from '../../../config';
import i18n from '../../../i18n';
import { getModalsAddDataSelector, getModalsIsLoadingSelector } from '../../../redux-store/selectors/modals';

const { REACT_APP_STAGES } = process.env;

const minLen3 = minLength(3);
const maxLen50 = maxLength(50);

const getAdminId = (companies, pviId) => {
  if (!pviId) {
    return undefined;
  }
  const chosenCompany = companies.find((item) => item.id === pviId) || {};
  return chosenCompany?.adminId;
};

const transformResponse = (response) => {
  const data = response.list
    .filter((user) => user?.my_company?._id && user?.my_company?.name && user?.my_company?.admin)
    .map(({ my_company: { _id, name, admin: adminId } }) => ({ id: _id, _id, name, adminId }));
  return { data };
};

const selector = formValueSelector(addUserForm);

const formSelector = createSelector(
  (state) => selector(state, 'sendOemTemplate', 'sm_id', 'pv_installer', 'email', 'connectedOem'),
  (formValues) => formValues
);

/**
 * Renders add End user modal window for Users page
 * @memberof module:UsersTable
 */
const AddUserWindow = (props) => {
  const { handleSubmit, pristine, valid, initialize, initialValues } = props;
  const dispatch = useDispatch();

  const formValues = useSelector(formSelector);
  const companies = useSelector(getDataListCompaniesDataSelector);
  const myRoleType = useSelector(getSignInUserRoleTypeSelector);
  const isLoading = useSelector(getModalsIsLoadingSelector);
  const modal = useSelector(getModalsAddDataSelector);
  const oems = useSelector(getUsersOemsListSelector);

  const hasSupportRights = ['root', 'solar_admin'].includes(myRoleType);
  const hasInstallerRights = ['pv_installer_employee', 'pv_installer', 'oem', 'oem_employee'].includes(myRoleType);

  const { connectedOem: chosenOEM, pv_installer: chosenPVInstaller } = formValues;

  const companiesList = useMemo(() => [{ value: 'null', label: i18n.t('NoPVinstaller') }, ...companies.map((item) => ({ value: item.id, label: item.name }))], [companies]);
  const oemsList = useMemo(() => (oems ? [...oems.map((item) => ({ value: item.oem._id, label: `${item.oem.name}` })), { value: 'null', label: i18n.t('NoOEM') }] : null), [oems]);

  const handleOnClose = () => dispatch(closeModalWindow({ modalID: ADD_DATA_MODAL_ID }));

  const { url, params } = getPvInstallersConfig[myRoleType] || getPvInstallersConfig.default;

  const displayOemDropdown = ((hasSupportRights && chosenPVInstaller && chosenPVInstaller !== 'null')
    || (hasInstallerRights && !['oem', 'oem_employee'].includes(myRoleType)))
    && (oemsList?.length > 1);
  const displayRadioButtons = !!(chosenOEM && chosenOEM !== 'null');

  const isAllowedToUsePropertyManagementFlow = ['root', 'solar_admin', 'pv_installer', 'pv_installer_employee', 'oem', 'oem_employee'].includes(myRoleType) && APP_ID === SOLAR_MANAGER_APP_ID;

  useEffect(() => {
    if (hasInstallerRights && modal?.opened && !['oem', 'oem_employee'].includes(myRoleType)) {
      dispatch(getOEMs());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal?.opened]);

  useEffect(() => {
    if (modal?.opened && (chosenPVInstaller || hasInstallerRights) && !['oem', 'oem_employee'].includes(myRoleType)) {
      initialize({ ...formValues, connectedOem: oemsList?.[0]?.value });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oems, chosenPVInstaller, modal?.opened, hasInstallerRights]);

  useEffect(() => {
    if (hasSupportRights) {
      initialize({ ...initialValues, ...formValues });
      const adminId = getAdminId(companies, chosenPVInstaller);
      const request = {
        property_management: {
          params: { pvInstallerId: chosenPVInstaller },
          ready: chosenPVInstaller && chosenPVInstaller !== 'null'
        },
        default: {
          url: `${OEM_CONNECTED_OEMS_URL}/${adminId}`,
          params: {},
          ready: !!adminId
        }
      };

      const { url: _url, params: _params, ready } = request?.[myRoleType] || request.default;

      if (ready) {
        dispatch(getOEMsByID({ url: _url, params: _params }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasSupportRights, chosenPVInstaller, myRoleType]);

  useEffect(() => {
    if (!modal?.opened) {
      dispatch(clearOEMs());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearOEMs, modal?.opened]);

  return (
    <ModalWindow modalID={ADD_DATA_MODAL_ID}>
      <DataList
        listID={dataListPviId}
        listURL={url}
        params={params}
        transformResponse={transformResponse}
      />
      <div className="modal-header">
        <h5 className="modal-title">{i18n.t('addSMU')}</h5>
      </div>
      {isLoading ? (
        <div className="preloader-container">
          <Preloader />
        </div>
      ) : (
        <div>
          <div className="modal-body">
            <form
              id={addUserAdmFormID}
              onSubmit={handleSubmit}
              className="m-login__form m-form pop-up-form add-sm-us"
            >
              <div className="form-group m-form__group input-field">
                <Field
                  name="sm_id"
                  component={CustomTextInput}
                  label={i18n.t('reqSMID')}
                  className="m-input"
                  validate={[required, minLen3, maxLen50]}
                />
              </div>
              {myRoleType !== 'pv_installer' && !EMPLOYEE_ROLE_TYPES.includes(myRoleType) && (
                <div className="form-group m-form__group input-field">
                  <div className="flags-select-label">{i18n.t('reqPVI')}</div>
                  <Field
                    name="pv_installer"
                    component={CustomSelect}
                    placeholder={i18n.t('selectPVI')}
                    options={companiesList}
                    validate={[required]}
                    searchable
                    disabled={['property_management'].includes(myRoleType)}
                  />
                </div>
              )}
              <div className="form-group m-form__group input-field">
                <Field
                  name="email"
                  component={CustomTextInput}
                  label={i18n.t('regMail')}
                  className="m-input"
                  autoComplete="off"
                  validate={isAllowedToUsePropertyManagementFlow ? [required] : [required, email]}
                  preventAuto
                />
              </div>
              {displayOemDropdown && (
                <>
                  <div className="form-group m-form__group input-field">
                    <div className="flags-select-label">
                      {i18n.t('oem')}
                      <Tooltip title={i18n.t('oemTooltip')} />
                    </div>
                    <Field
                      name="connectedOem"
                      component={CustomSelect}
                      placeholder={i18n.t('selectOEM')}
                      options={oemsList}
                      searchable
                    />
                  </div>
                  <div
                    className={`form-group m-form__group input-field ${displayRadioButtons ? '' : 'disabled'} radio-oem`}
                  >
                    <Field
                      name="sendOemTemplate"
                      component={RadioButtons}
                      props={{
                        disabled: !displayRadioButtons,
                        defaultValue: displayRadioButtons,
                        variants: [
                          {
                            label: <span className="white dataSMID">{i18n.t('oemEmail')}</span>,
                            value: true
                          },
                          {
                            label: (
                              <span className="white dataSMID">{i18n.t('installerEmail')}</span>
                            ),
                            value: false
                          }
                        ]
                      }}
                    />
                  </div>
                </>
              )}
              {/* For new helion app and for dev env for the moment */}
              {(APP_ID !== HELION_APP_ID || REACT_APP_STAGES !== 'development') ? null : (
                <div className="form-group m-form__group input-field">
                  <Field
                    type="checkbox"
                    name="appVersion"
                    component={Checkbox}
                    label="use new app version?"
                  />
                </div>
              )}
            </form>
          </div>
          <div className="modal-footer">
            <CancelButton onClickHandler={handleOnClose} />
            <PrimaryButton form={addUserAdmFormID} disabled={pristine && !valid} isSubmit type="send" />
          </div>
        </div>
      )}
    </ModalWindow>
  );
};

AddUserWindow.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  initialize: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  valid: PropTypes.bool.isRequired,
  initialValues: PropTypes.instanceOf(Object).isRequired
};

const form = reduxForm({
  form: addUserForm

})(AddUserWindow);

export default form;
