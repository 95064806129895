import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Field, change, reduxForm } from 'redux-form';
import { Tabs, Tab } from '@mui/material';

import { CustomTextField } from '../../components/ReduxFormFields';
import { PrimaryButton, CancelButton } from '../../components/UIKit';
import ModalWindow from '../ModalWindow';
import { FORM_ID } from './constants';
import { setPagesSetting } from '../PagesSettings/slice';
import { closeModalWindow, CLOUD_STATUS_MODAL_ID } from '../ModalWindow/slice';
import { getSignInUserRoleTypeSelector } from '../../redux-store/selectors/signIn';
import { getModalsCloudStatusSelector } from '../../redux-store/selectors/modals';
import { getPagesSettingsSelector } from '../../redux-store/selectors/pagesSettings';

import i18n from '../../i18n';

import './index.scss';

const CloudStatusWindow = (props) => {
  const { handleSubmit, anyTouched, initialize, invalid } = props;
  const dispatch = useDispatch();

  const modal = useSelector(getModalsCloudStatusSelector);
  const pagesSetting = useSelector(getPagesSettingsSelector);
  const myRoleType = useSelector(getSignInUserRoleTypeSelector);

  const { opened, data } = modal;
  const [activeTab, setActiveTab] = useState(0);
  const [messages, setMessages] = useState({
    maintenance_message: data?.maintenance_message?.message || '',
    message_extended: data?.maintenance_message_extended?.message || ''
  });

  const messageName = activeTab === 0 ? 'maintenance_message' : 'maintenance_message_extended';
  const currentExtended = activeTab !== 0;

  const isAdmin = ['root', 'solar_admin'].includes(myRoleType);

  const handleTabChange = (_, value) => {
    setActiveTab(value);
    dispatch(change(FORM_ID, 'extended', currentExtended));
  };

  const onChangeHandler = (messName, e) => setMessages((prev) => ({ ...prev, [messName]: e?.target?.value }));
  const onClose = () => dispatch(closeModalWindow({ modalID: CLOUD_STATUS_MODAL_ID }));

  useEffect(() => {
    if (opened) {
      if (isAdmin) {
        initialize({
          [messageName]: messages?.[messageName] || data?.[messageName]?.message || '',
          extended: currentExtended
        });
      } else {
        const { createdAt: savedCreatedAt } = pagesSetting.cloudStatusMessage;
        initialize({
          maintenance_message: data?.message || i18n.t('cloudStatusNoMessage'),
          extended: currentExtended
        });

        if (!savedCreatedAt || (data?.createdAt && savedCreatedAt?.createdAt !== data?.createdAt)) {
          dispatch(setPagesSetting({ page: 'cloudStatusMessage', data: { createdAt: data?.createdAt } }));
        }
      }
    }

    if (!opened) {
      setMessages({
        maintenance_message: data?.maintenance_message?.message || '',
        maintenance_message_extended: data?.maintenance_message_extended?.message || ''
      });
      initialize();
      setActiveTab(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opened, data, messageName, currentExtended]);

  return (
    <div className="cloudStatusModal">
      <ModalWindow modalID={CLOUD_STATUS_MODAL_ID}>
        <div className="modal-header">
          <h5 className="modal-title">{i18n.t('cloudStatus')}</h5>
        </div>
        <div className="modal-body">
          <form
            id={FORM_ID}
            onSubmit={handleSubmit}
            className="m-login__form m-form pop-up-form add-sm-us"
          >
            {isAdmin ? (
              <Tabs
                value={activeTab}
                onChange={handleTabChange}
                textColor="inherit"
              >
                <Tab disableRipple value={0} label={i18n.t('End User')} />
                <Tab disableRipple value={1} label={i18n.t('pvInstallerOem')} />
              </Tabs>
            ) : null}
            <div className="form-group m-form__group">
              {isAdmin ? (
                <div className={`flags-select-label ${anyTouched && invalid ? 'textarea-custom-warning' : ''}`}>
                  {i18n.t('message')}
                </div>
              ) : null}
              <Field
                name={messageName}
                onChange={onChangeHandler.bind(null, messageName)}
                component={CustomTextField}
                placeholder={i18n.t('messagePlaceholder')}
                className="cloud-status-message"
                readOnly={!isAdmin}
              />
            </div>
          </form>
        </div>
        <div className="modal-footer">
          <CancelButton onClickHandler={onClose} i18nLabel={isAdmin ? 'cancel' : 'close'} />
          {isAdmin && <PrimaryButton form={FORM_ID} isSubmit type="save" />}
        </div>
      </ModalWindow>
    </div>
  );
};

CloudStatusWindow.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  initialize: PropTypes.func.isRequired,
  anyTouched: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired
};

const form = reduxForm({
  form: FORM_ID
})(CloudStatusWindow);

export default form;
