import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';

import { required, equals, notEqualValue, passwordAdvanced } from '../../validation';
import { CustomTextInput } from '../../../components/ReduxFormFields';
import ModalWindow from '../../ModalWindow';
import { Preloader } from '../../../components';
import { PrimaryButton, CancelButton } from '../../../components/UIKit';
import { getModalsChangePasswordSelector, getModalsIsLoadingSelector } from '../../../redux-store/selectors/modals';
import { changePassWindowId } from '../constants';
import { CHANGE_PASSWORD_MODAL_ID, closeModalWindow } from '../../ModalWindow/slice';

import i18n from '../../../i18n';

const validateConfirmPass = equals('password');
const validateOldPassword = notEqualValue('old_password', 'newOldPass');

/**
 * Modal window for changin password from edit profile page.
 * Only for himself.
 * @memberof module:SettingProfile
 */
const ChangePassWindow = (props) => {
  const { handleSubmit, pristine, reset } = props;
  const dispatch = useDispatch();

  const modal = useSelector(getModalsChangePasswordSelector);
  const isLoading = useSelector(getModalsIsLoadingSelector);

  const handleOnClose = () => dispatch(closeModalWindow({ modalID: CHANGE_PASSWORD_MODAL_ID }));

  useEffect(() => {
    if (!modal?.opened) {
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal?.opened]);

  return (
    <ModalWindow modalID={CHANGE_PASSWORD_MODAL_ID}>
      <div className="modal-header">
        <h5 className="modal-title">{i18n.t('changePass')}</h5>
      </div>
      {isLoading ? <div className="preloader-container"><Preloader /></div> : (
        <>
          <div className="modal-body">
            <form
              id={changePassWindowId}
              onSubmit={handleSubmit}
              className="m-login__form m-form pop-up-form add-sm-us"
            >
              <div className="form-group m-form__group input-field">
                <Field
                  name="old_password"
                  component={CustomTextInput}
                  label={i18n.t('reqOldPass')}
                  className="m-input"
                  type="password"
                  autoComplete="off"
                  validate={[required]}
                />
              </div>
              <div className="form-group m-form__group input-field">
                <Field
                  name="password"
                  component={CustomTextInput}
                  label={i18n.t('reqNewPass')}
                  className="m-input"
                  type="password"
                  autoComplete="off"
                  preventAuto
                  validate={[required, passwordAdvanced]}
                />
              </div>
              <div className="form-group m-form__group input-field">
                <Field
                  name="passwordConfirmation"
                  component={CustomTextInput}
                  label={i18n.t('repReqNewPass')}
                  type="password"
                  className="m-input"
                  autoComplete="off"
                  validate={[required, passwordAdvanced, validateConfirmPass, validateOldPassword]}
                />
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <CancelButton onClickHandler={handleOnClose} />
            <PrimaryButton form={changePassWindowId} disabled={pristine} isSubmit i18nLabel="changeBtn" type="save" />
          </div>
        </>
      )}
    </ModalWindow>
  );
};

ChangePassWindow.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired
};

const form = reduxForm({
  form: 'changePassForm'
})(ChangePassWindow);

export default form;
